import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { EventType } from "../typeDefs";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export const Events = () => {
  const [eventArray, setEventArray] = useState<EventType[]>([]);

  useEffect(() => {
    const q = query(
      collection(db, "events"),
      where("isDeleted", "==", false),
      where("date", ">", new Date().getTime()),
      where("date", "<", new Date().getTime() + 1000 * 60 * 60 * 36)
    );
    const unsub = onSnapshot(q, (snapshot) => {
      setEventArray([]);
      snapshot.docs.forEach((doc) => {
        setEventArray((prev) => {
          return [...prev, doc.data() as EventType];
        });
      });
    });
    return unsub;
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        zIndex: 20,
        alignSelf: "center",
        alignItems: "center",
        padding: "10px",
        width: "100%",
      }}
    >
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          350: 1,
          750: 2,
          900: 3,
        }}
        style={{ width: "100%", maxWidth: "1000px" }}
      >
        <Masonry>
          {eventArray
            .sort((a, b) => a.date - b.date)
            .map((event, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "320px",
                  backgroundColor:
                    event.date < new Date().getTime() + 1000 * 60 * 60 * 12
                      ? "rgba(130,50,50,.6)"
                      : "rgba(40,40,40,.9)",
                  border:
                    event.date < new Date().getTime() + 1000 * 60 * 60 * 12
                      ? "1px solid rgb(200,100,100)"
                      : "1px solid rgb(100,100,100)",
                  padding: "10px",
                  borderRadius: "3px",
                  color: "white",
                  boxShadow: "2px 2px 10px 5px rgba(30,0,0,.5)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "left",
                    fontSize: ".75rem",
                    fontWeight: "bold",
                    marginBottom: "5px",
                    color:
                      event.date < new Date().getTime() + 1000 * 60 * 60 * 12
                        ? "rgb(255,70,70)"
                        : "rgb(140,140,140)",
                  }}
                >
                  {event.type}
                </div>
                <div
                  style={{
                    backgroundColor:
                      event.date < new Date().getTime() + 1000 * 60 * 60 * 12
                        ? "rgba(130,50,50,.9)"
                        : "rgba(40,40,40,.9)",
                    border:
                      event.date < new Date().getTime() + 1000 * 60 * 60 * 12
                        ? "1px solid rgb(150,50,50)"
                        : "1px solid rgb(100,100,100)",
                    padding: "10px",
                    borderRadius: "3px",
                  }}
                >
                  <div style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
                    {event.title}
                  </div>

                  <div>{new Date(event.date).toDateString()}</div>
                  <div>{new Date(event.date).toLocaleTimeString("en-NZ")}</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontSize: "1.1rem",
                  }}
                >
                  <div style={{ textAlign: "justify" }}>
                    {event.description}
                  </div>
                </div>
              </div>
            ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
