import { ConfigProvider } from "antd";
import "./App.css";
import { Foreground } from "./Foreground/Foreground";

function App() {
  return (
    <ConfigProvider
    // theme={{
    //   token: {
    //     colorPrimary: '#00b96b',
    //   },
    // }}
    >
      <div className="App">
        <Foreground />
      </div>
    </ConfigProvider>
  );
}

export default App;
