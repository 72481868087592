import { ControlOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const FinanceLibrary = () => {
  const nav = useNavigate();
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        zIndex: "15",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(40,40,40,.9)",
        color: "rgb(200,200,200)",
      }}
    >
      <div
        style={{
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "5rem",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            maxWidth: "900px",
            textAlign: "left",
            background: "rgba(40,40,40,.8)",
            padding: "2rem",
            borderRadius: "10px",
            boxShadow: "0px 5px 10px 10px rgba(0,0,0,.4)",
            marginTop: "1rem",
          }}
        >
          <div
            style={{
              //marginTop: "2rem",
              fontSize: "2rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Finance Library
          </div>

          <div>
            <div
              style={{
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Revenue
            </div>
            <div>
              <strong>Sales Revenue:</strong> This is the income generated from
              selling goods or services to customers. It is the primary source
              of revenue for most businesses.
            </div>
            <div>
              <strong>Service Revenue:</strong> Revenue earned from providing
              services rather than selling physical products. This includes
              consulting, repair, and maintenance services.
            </div>
            <div>
              <strong>Interest Income:</strong> Earnings from investments that
              pay interest, such as bonds or savings accounts. It reflects the
              return on financial assets.
            </div>
            <div>
              <strong>Dividend Income:</strong> Income received from owning
              shares in a corporation, distributed from the company's profits.
            </div>
            <div>
              <strong>Rental Income:</strong> Earnings from leasing out property
              or equipment. It is a common revenue stream for real estate
              businesses.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Expenses
            </div>
            <div>
              <strong>Cost of Goods Sold (COGS):</strong> The direct costs
              attributable to the production of the goods sold by a company. It
              includes material and labor costs.
            </div>
            <div>
              <strong>Salaries and Wages:</strong> Payments made to employees
              for their services. It is a significant operating expense for most
              businesses.
            </div>
            <div>
              <strong>Rent Expense:</strong> The cost incurred by a business to
              use property or equipment it does not own. It is a fixed expense
              in many cases.
            </div>
            <div>
              <strong>Utilities Expense:</strong> Costs for services such as
              electricity, water, and gas that are necessary for business
              operations.
            </div>
            <div>
              <strong>Depreciation Expense:</strong> The allocation of the cost
              of a tangible asset over its useful life. It reflects wear and
              tear on physical assets.
            </div>
            <div>
              <strong>Interest Expense:</strong> The cost incurred by an entity
              for borrowed funds. It is the price paid for using another's
              money.
            </div>
            <div>
              <strong>Insurance Expense:</strong> The cost of insuring the
              business against various risks, including property damage and
              liability claims.
            </div>
            <div>
              <strong>Advertising Expense:</strong> Costs associated with
              promoting products or services to increase sales and market
              presence.
            </div>
            <div>
              <strong>Subscriptions:</strong> Regular payments for services or
              products provided on a recurring basis, such as software or
              publications.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Assets
            </div>
            <div>
              <strong>Cash and Cash Equivalents:</strong> Liquid assets that are
              readily convertible to cash, including currency, bank accounts,
              and short-term investments.
            </div>
            <div>
              <strong>Accounts Receivable:</strong> Money owed to a company by
              its customers for goods or services delivered but not yet paid
              for.
            </div>
            <div>
              <strong>Inventory:</strong> Goods available for sale and raw
              materials used to produce goods available for sale. It is a
              current asset on the balance sheet.
            </div>
            <div>
              <strong>Prepaid Expenses:</strong> Payments made in advance for
              goods or services to be received in the future, such as insurance
              or rent.
            </div>
            <div>
              <strong>Property, Plant, and Equipment (PP&E):</strong> Long-term
              tangible assets used in the operations of a business, including
              land, buildings, and machinery.
            </div>
            <div>
              <strong>Intangible Assets:</strong> Non-physical assets such as
              patents, trademarks, and goodwill that provide long-term value to
              a company.
            </div>
            <div>
              <strong>Investments:</strong> Assets purchased with the
              expectation of generating income or profit, such as stocks, bonds,
              or real estate.
            </div>
            <div>
              <strong>Office Fit-Out:</strong> The cost associated with
              furnishing and equipping office space to make it functional for
              business operations.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Liabilities
            </div>
            <div>
              <strong>Accounts Payable:</strong> Obligations to pay short-term
              debts to suppliers and creditors for goods or services received.
            </div>
            <div>
              <strong>Short-term Debt:</strong> Borrowings that are due to be
              paid within one year, including lines of credit and short-term
              loans.
            </div>
            <div>
              <strong>Long-term Debt:</strong> Loans and financial obligations
              with a maturity of more than one year.
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: "2rem",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Depreciation
            </div>
            <div>
              <strong>Straight Line</strong> Depreciation = Book Value at Start
              of Year X Depreciation Rate
            </div>
            <div>
              <strong>Diminishing Value:</strong> Depreciation = Book Value at
              Start of Year X Depreciation Rate X Number of Years
            </div>
            <div>
              <strong>Remember,</strong> tax laws and rates can change, so it's
              a good idea to consult the latest guidelines from Inland Revenue
              or seek advice from a tax professional to ensure compliance and
              accuracy in your calculations.
            </div>
            <div>
              <strong>Count part-months as whole months.</strong>If you bought
              the asset partway through the financial year, or if you started
              using it for business partway through the year, then you need to
              work out depreciation for the number of months you used it for
              business. Count part-months as whole months.
            </div>
            <a href="https://www.ird.govt.nz/income-tax/income-tax-for-businesses-and-organisations/types-of-business-expenses/depreciation/claiming-depreciation/work-out-straight-line-depreciation">
              Learn More
            </a>
          </div>
          <Button
            type="text"
            style={{ fontFamily: "Oxanium", margin: ".5rem", color: "white" }}
            icon={<ControlOutlined />}
            onClick={() => nav("/finance-reports")}
          />
        </div>
      </div>
    </div>
  );
};
